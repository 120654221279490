<template>
  <page :styleWrap="{'background': '#fff'}">
    <div style="padding: 15px">
      <div class="phoneTip">请设置登录密码</div>

      <div>
        <van-field class="f_flex f_a_c fieldCell" v-model="pwd" label-width="80" :type="inputType">
          <div slot='label' style="font-size: 15px;  color: #BABABA">输入密码</div>
          <template #button>
            <toggle-pwd :inputType="inputType" @change='changeInputType'></toggle-pwd>
          </template>
        </van-field>
        <p style="font-size: 12px; color: #9B9B9B; margin-top: 8px">请设置6-18位密码</p>
      </div>

      <button class="btn_radius margin_auto register_btn " :class="[pwd ? 'theme_bg' : 'theme_dis_bg']">确定</button>
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
import togglePwd from './togglePwd.vue'

export default {
  data: ()=> ({
    pwd: '',
    inputType: 'password'
  }),
  components: {
    'van-field': Field,
    'toggle-pwd': togglePwd
  },
  methods: {
    changeInputType(e) {
      this.inputType = e
    }
  },
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-top: 60px;
  margin-bottom: 45px;
}
.fieldCell {
  height: 58px;
  font-size: 15px;
  border-bottom: 1px solid #E4E4E4;
  padding: 0;
}
.fieldLabel {
  color: #BABABA;
  font-size: 15px;
}
.register_btn {
  width: 70%;
  margin-top: 94px;
}
</style>